import React from 'react';
import Link from '../svg/Link';
import Facebook from '../svg/Facebook';
import Twitter from '../svg/Twitter';
import LinkedIn from '../svg/LinkedIn';
import Instagram from '../svg/Instagram';
import '../styles/ShareCard.css';

/**
 * Displays the social icons and share links for the current article in the sidebar
 * 
 * @returns {JSX} React Component 
 */
const ShareCard = () => {
    return (
        <div className='share-card'>
            <p style={{marginBottom: 9}}>Share:</p>
            <div style={{display: 'flex', flexDirection: 'row', flewWrap: 'wrap', gap: 12}}>
                <a href='https://onlock.ca'><Link/></a>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(window.location.href)}`}><Facebook/></a>
                <a href='https://onlock.ca'><Twitter/></a>
                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(window.location.href)}`}><LinkedIn/></a>
                <a href='https://onlock.ca'><Instagram/></a>
            </div>
        </div>
    )
}   

export default ShareCard;