import React, { useState } from 'react';
import PolygonBox from './PolygonBox';
import { Center, Text } from "@chakra-ui/react";

/**
 * Displays a clickable, octogonal button
 * 
 * @param {string} id - id of the clickable area of the button
 * @param {string} text - text content of the button
 * @param {number} width - width (in px) of button. If not specified, width will be calculated based on font size and text length
 * @param {number} height - height (in px) of button. If not specified, then 26px
 * @param {number} radius - length (in px) of diagonal corners
 * @param {string} btnStyle - colour-style of the button (white | black)
 * @param {function} onClick - action that occurs when button is clicked
 * @param {string} fontSize - font size of button text. If not specified, then 15pt
 * @param {string} fontWeight - font weight of button text (normal, bold, bolder, etc.)
 * @param {JSX} children - child elements of the button 
 * @returns {JSX} React Component
 */
const OctagonButton = ({ id, text, width, height, radius, btnStyle='white', onClick, fontSize='15pt', fontWeight='normal', children }) => {
    const [hover, setHover] = useState(false);

    const buttonStyles = {
        white: {
            bg: 'white',
            bg_hover: '#007D77',
            text: 'black',
            text_hover: 'white',
            border: 'black',
            border_hover: '#007D77'
        },
        black: {
            bg: 'black',
            bg_hover: '#007D77',
            text: 'white',
            text_hover: 'white',
            border: 'black',
            border_hover: '#007D77'
        }
    }

    if (!width) {
        const font = `${fontWeight} ${fontSize} Forma DJR Deck`;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        ({ width } = context.measureText(text));
    }
    if (!height) height = 26;
    if (!radius) radius = 6;

    return (
        <PolygonBox
            width={width}
            height={height}
            radius={radius}
            radiusWidth={1}
            outerBgColor={hover ? buttonStyles[btnStyle].border_hover : buttonStyles[btnStyle].border}
            >
            <Center 
                id={id}
                cursor={"pointer"} 
                h={"full"} 
                onClick={() => onClick()}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                bg={hover ? buttonStyles[btnStyle].bg_hover : buttonStyles[btnStyle].bg}
                >
                {text && <Text color={hover ? buttonStyles[btnStyle].text_hover : buttonStyles[btnStyle].text}>{text}</Text>}
                {children && children}
            </Center>
        </PolygonBox>
    );
}

export default OctagonButton;