import React from "react";

const Logo = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='106'
      height='18'
      fill='none'
      viewBox='0 0 106 18'
    >
      <g fill='#000' clipPath='url(#clip0_368_6387)'>
        <path d='M3.62 3.63H0v10.745h3.62V3.629z'></path>
        <path d='M17.956 3.63h-3.62v10.745h3.62V3.629z'></path>
        <path d='M14.338 3.63V.001H3.618V3.63h10.72z'></path>
        <path d='M14.338 18.002v-3.629H3.618v3.63h10.72z'></path>
        <path d='M23.362 3.63h-3.62V18h3.62V3.63z'></path>
        <path d='M31.456 3.63h-3.62v10.745h3.62V3.629z'></path>
        <path d='M39.55 0h-3.62v14.371h3.62V0z'></path>
        <path d='M14.336 0v3.629h3.62L14.336 0z'></path>
        <path d='M27.836 0v3.629h3.62L27.836 0z'></path>
        <path d='M0 3.629h3.62V0L0 3.629z'></path>
        <path d='M19.742 3.629h3.62V0l-3.62 3.629z'></path>
        <path d='M3.62 18v-3.63H0L3.62 18z'></path>
        <path d='M44.31 0h-3.62v14.371h3.62V0z'></path>
        <path d='M55.027 18v-3.629H44.308v3.63h10.72z'></path>
        <path d='M44.306 18v-3.63H40.69L44.306 18z'></path>
        <path d='M17.956 14.37h-3.62V18l3.62-3.63z'></path>
        <path d='M58.645 3.63h-3.62v10.745h3.62V3.629z'></path>
        <path d='M72.981 3.63h-3.62v10.745h3.62V3.629z'></path>
        <path d='M69.361 3.631V.002H58.642v3.629h10.72z'></path>
        <path d='M69.363 18.003v-3.63H58.644v3.63h10.72z'></path>
        <path d='M69.361 0v3.629h3.62L69.361 0z'></path>
        <path d='M55.025 3.629h3.62V0l-3.62 3.629z'></path>
        <path d='M58.645 18v-3.63h-3.62l3.62 3.63z'></path>
        <path d='M77.737 3.63h-3.62v10.745h3.62V3.629z'></path>
        <path d='M88.455 3.629V0h-10.72v3.629h10.72z'></path>
        <path d='M88.457 18.001v-3.629h-10.72v3.63h10.72z'></path>
        <path d='M74.117 3.629h3.62V0l-3.62 3.629z'></path>
        <path d='M77.737 18v-3.63h-3.62l3.62 3.63z'></path>
        <path d='M72.981 14.37h-3.62V18l3.62-3.63z'></path>
        <path d='M24.497 0h-1.136v3.629h1.136V0z'></path>
        <path d='M27.835 0h-4.474v3.629h4.474V0z'></path>
        <path d='M35.93 18v-3.63h3.62L35.93 18z'></path>
        <path d='M27.836 14.37h3.62V18l-3.62-3.63z'></path>
        <path d='M32.591 14.37h-1.136V18h1.136v-3.63z'></path>
        <path d='M35.93 14.37h-4.475V18h4.474v-3.63z'></path>
        <path d='M105.999 0h-3.62v7.187h3.62V0z'></path>
        <path d='M93.472 0h-3.62v18h3.62V0z'></path>
        <path d='M105.999 7.188h-3.62v3.625l3.62-3.626z'></path>
        <path d='M105.999 10.813h-3.62V18h3.62v-7.188z'></path>
        <path d='M105.999 10.813h-3.62V7.187l3.62 3.626z'></path>
        <path d='M102.38 7.188h-9.501v3.628h9.501V7.188z'></path>
      </g>
      <defs>
        <clipPath id='clip0_368_6387'>
          <path fill='#fff' d='M0 0H106V18H0z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;