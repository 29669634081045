import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import '../styles/Transcription.css';

/**
 * Displays a video transcription in an article. Supports markdown formatting
 * 
 * @param {string} text - the transcription to display 
 * @returns {JSX} React Component
 */
const Transcription = ({ text }) => {
    return (
        <div className='article-transcription'>
            <h3>Transcript</h3>
            <ReactMarkdown>{text}</ReactMarkdown>
        </div>
    );
}

export default Transcription;