import React from 'react';
import '../styles/Breadcrumb.css';

/**
 * Represents the breadcrumb that appears at the top of an article page
 * 
 * @param {Object[]} links - array of links to form the breadcrumb 
 * @returns {JSX} React Component
 */
const Breadcrumb = ({ links }) => {
    return (
        <div className='breadcrumb'>
            {links.map((link, i) => <><a key={i} href={link?.href}>{link?.text}</a>{i < links.length - 1 && <span className='breadcrumb-gt'>&gt;</span>}</>)}
        </div>
    );
}

export default Breadcrumb;