const Input = {
    variants: {
      underlined: {
        field: {
          color: "#000",
          fontWeight: 400,
          border: "none",
          borderBottom: "1px solid #000",
          borderRadius: "none",
          _focus: {
            boxShadow: "none",
            borderBottom: "1px solid #000"
          },
          _hover: {
            boxShadow: "none",
            borderBottom: "1px solid #000"
          }
        }
      },
      outline: {
        field: {
          "::placeholder": {
            color: "#828282",
            fontFamily: "Forma DJR Deck",
            letterSpacing: "0.03em"
          },
          fontWeight: 400,
          border: "2px solid #828282!important",
          borderRadius: "none",
          color: "#000"
        }
      }
    }
  }

export default Input;