import React from 'react';
import Logo from '../svg/Logo';
import '../styles/Header.css';

const Header = ({ includeSearch }) => {
    
    const enterPress = e => {
        if (e.key === 'Enter') {
            window.location.href = '/?search=' + encodeURIComponent(document.getElementById('mini-search').value);
        }
    }

    return (
        <header>
            <div className='header-content-wrapper'>
                <div className='logo-wrapper'>
                    <a href='/'><Logo/></a>
                </div>
                {includeSearch && 
                    <div className='search-div-header'>
                        <span className='search-magnifying-glass'>⚲</span>
                        <input id='mini-search' type='text' placeholder='Search' onKeyDown={enterPress}></input>
                    </div>}
            </div>
        </header>
    )
}

export default Header;