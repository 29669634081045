import React from "react";

function Twitter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
    >
      <g clipPath="url(#clip0_434_2648)">
        <path
          fill="#000"
          d="M23.625 7.543c-.758.32-1.563.53-2.386.623a4.01 4.01 0 001.826-2.19 8.575 8.575 0 01-2.638.961 4.248 4.248 0 00-3.032-1.25c-2.294 0-4.155 1.772-4.155 3.958 0 .31.037.612.108.902-3.453-.165-6.514-1.74-8.563-4.135a3.799 3.799 0 00-.563 1.99c0 1.373.734 2.584 1.848 3.294A4.296 4.296 0 014.19 11.2v.05c0 1.917 1.431 3.517 3.332 3.88a4.36 4.36 0 01-1.876.068c.528 1.573 2.063 2.717 3.88 2.75a8.602 8.602 0 01-5.159 1.693c-.335 0-.666-.018-.991-.055a12.187 12.187 0 006.368 1.778c7.642 0 11.82-6.03 11.82-11.26 0-.173-.003-.343-.011-.513a8.233 8.233 0 002.073-2.049z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_434_2648">
          <path
            fill="#fff"
            d="M0 0H20.25V15.75H0z"
            transform="translate(3.375 5.625)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Twitter;