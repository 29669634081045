const TextArea = {
    variants: {
        outline: {
            '::placeholder': {
                color: '#828282',
                fontFamily: 'Forma DJR Deck',
                letterSpacing: '0.03em'
            },
            fontWeight: 400,
            border: '2px solid #828282!important',
            borderRadius: 'none',
            color: '#000'
        }
    },
};

export default TextArea;