import React from 'react';
import TagsCard from './TagsCard';
import ShareCard from './ShareCard';
import FeedbackCard from './FeedbackCard';
import '../styles/ArticleSidebarRight.css';

/**
 * The sidebar that appears on the right-side of an article; sticky to top on desktop,
 * appears after article on mobile
 * 
 * @param {Object[]} tags - tags to populate tags card with
 * @param {string} id - id of current article
 * @param {string} helpful - count of how many times article has been indicated as helpful 
 * @param {string[]} feedback - array of strings of feedback recieved from readers
 * @returns {JSX} React Component
 */
const ArticleSidebarRight = ({ tags, id, helpful, feedback }) => {
    tags = tags.map(tag => tag?.attributes?.name);
    
    return (
        <div className='article-sidebar-right'>
            <TagsCard tags={tags}/>
            <ShareCard />
            <FeedbackCard id={id} helpful={helpful} feedback={feedback}/>
        </div>
    )
}

export default ArticleSidebarRight;