import React from "react";

function Facebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
    >
      <g clipPath="url(#clip0_434_2644)">
        <path
          fill="#000"
          d="M11.01 21.375v-7.39H9v-2.662h2.01V9.051c0-1.786 1.188-3.426 3.924-3.426 1.108 0 1.927.103 1.927.103l-.064 2.485s-.836-.008-1.748-.008c-.986 0-1.144.442-1.144 1.176v1.942h2.97l-.13 2.661h-2.84v7.39H11.01z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_434_2644">
          <path
            fill="#fff"
            d="M0 0H7.875V15.75H0z"
            transform="translate(9 5.625)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Facebook;