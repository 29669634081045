import React from 'react';
import '../styles/GlossaryCard.css';

/**
 * A card to display a term and its definition on the landing page
 * 
 * @param {string} term - the term to be defined 
 * @param {string} definition - the definition of the term
 * @returns {JSX} React Component
 */
const GlossaryCard = ({ term, definition }) => {
    return (
        <div className='glossary-card'>
            <div className='glossary-card-text'>
                <p className='glossary-card-term'>{term.toUpperCase()}</p>
                <p className='glossary-card-definition'>{definition}</p>
            </div>
            <div className='glossary-card-label'>
                <span>GLOSSARY</span>
            </div>
        </div>
    )
}

export default GlossaryCard;