import { Box } from "@chakra-ui/react"

/**
 * Draws an octogonal box around child elements
 * 
 * @param {number} width - width of the box (in px)
 * @param {number} height - height of the box (in px)
 * @param {number} radius - length of diagonal corners (in px)
 * @param {number} radiusWidth - width of border (in px)
 * @param {string} innerBgColor - colour of center of box
 * @param {string} outerBgColor - colour of border of box
 * @param {JSX} children - child elements of the box
 * @param {Object} rest - additional attributes for outer box not specified above
 * @returns {JSX} React Component
 */
const PolygonBox = ({ width, height, radius, radiusWidth, innerBgColor, outerBgColor, children, ...rest}) => {
  let radius_size = 1;
  if (radiusWidth) {
    radius_size = radiusWidth;
  }

  const outer_width = width;
  const outer_height = height;
  const outer_path = `polygon(${radius}px 0, ${outer_width - radius}px 0%, 100% ${radius}px, 100% ${outer_height - radius}px, ${outer_width - radius}px 100%, ${radius}px 100%, 0% ${outer_height - radius}px, 0% ${radius}px)`;
  const inner_height = outer_height - radius_size * 2;
  const inner_width = outer_width - radius_size * 2;
  const inner_path = `polygon(${radius}px 0, ${inner_width - radius}px 0%, 100% ${radius}px, 100% ${inner_height - radius}px, ${inner_width - radius}px 100%, ${radius}px 100%, 0% ${inner_height - radius}px, 0% ${radius}px)`;
  let inner_bg_color = '#fff';
  if (innerBgColor) {
    inner_bg_color = innerBgColor
  }
  let outer_bg_color = '#000';
  if (outerBgColor) {
    outer_bg_color = outerBgColor;
  }
  
  return (
    <Box
      w={outer_width + "px"}
      height={outer_height + "px"}
      p={radius_size + "px"}
      bg={outer_bg_color}
      clipPath={outer_path}
      {...rest}
    >
      <Box
        h={inner_height + "px"}
        w={inner_width + "px"}
        bg={inner_bg_color}
        clipPath={inner_path}
      >
        {children}
      </Box>
    </Box>
  )
}

export default PolygonBox;
