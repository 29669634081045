const Tabs = {
    variants: {
      bordered: props => {
        return {
          tab: {
            minW: "180px",
            bg: "#000",
            color: "#fff",
            clipPath: "polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)"
          }
        }
      }
    }
  }

export default Tabs;
  