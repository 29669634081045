import React from 'react';
import '../styles/CuratedSetCard.css';

/**
 * A card to display information about an article on the landing page
 * that's part of a curated set
 * 
 * @param {Object} article - JSON object containing all information of an article 
 * @returns {JSX} React Component
 */
const CuratedSetCard = ({ article }) => {
    
    const bucket = article?.attributes?.bucket?.data ? article.attributes.bucket.data : null;

    const convertDate = date => {
        const comps = date.split('-');
        let year = comps[0];
        let month = Number(comps[1]);
        let day = Number(comps[2]);

        switch (month) {
            case 1: month = 'January'; break;
            case 2: month = 'February'; break;
            case 3: month = 'March'; break;
            case 4: month = 'April'; break;
            case 5: month = 'May'; break;
            case 6: month = 'June'; break;
            case 7: month = 'July'; break;
            case 8: month = 'August'; break;
            case 9: month = 'September'; break;
            case 10: month = 'October'; break;
            case 11: month = 'November'; break;
            case 12: month = 'December'; break;
            default: month = 'error'; break;
        }

        return `${day} ${month} ${year}`;
    }

    const sidebarColours = bucketName => {
        switch (bucketName) {
            case 'Intellectual Propery':
                return {
                    backgroundColor: 'rgba(0, 125, 119, 0.3)',
                    color: 'black'
                };
            case 'Licensing on ONLOCK':
                return {
                    backgroundColor: 'rgba(0, 125, 119, 0.5)',
                    color: 'black'
                };
            case 'Terms & Templates':
                return {
                    backgroundColor: 'rgba(0, 125, 119, 0.7)',
                    color: 'white'
                };
            case 'Resources':
                return {
                    backgroundColor: 'rgba(0, 125, 119, 0.9)',
                    color: 'white'
                };
            default:
                return {
                    backgroundColor: 'rgb(0, 125, 119)',
                    color: 'white'
                }
        }
    }

    const sidebarStyle = sidebarColours(bucket.attributes.name);

    return (
        
            <div className='curated-set-card'>
                <div className='curated-set-card-left'>
                    <a className='curated-article-title' href={`/article?id=${article.id}`}><h3>{article.attributes.title}</h3></a>
                    <div className='curated-article-excerpt'>
                        {article.attributes.excerpt}
                    </div>
                    <div className='curated-article-card-spacer'></div>
                    <div className='curated-article-date'>{convertDate(article.attributes.published)}</div>
                </div>
                <div className='curated-set-card-right' style={sidebarStyle}>
                    <span className='curated-set-card-bucket'>{bucket.attributes.name}</span>
                </div>
            </div>
        
    );
}

export default CuratedSetCard;