import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
    return (
        <footer>
            <div className='footer-content'>
                ONLOCK
            </div>
        </footer>
    )
}

export default Footer;