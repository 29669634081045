import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import GlossaryCard from '../components/GlossaryCard';
import ArticleCard from '../components/ArticleCard';
import OctagonButton from '../components/OctagonButton';
import Header from '../Templates/Header';
import Footer from '../Templates/Footer';
import '../styles/FeedbackCard.css';
import axios from 'axios';
import CuratedSetCard from '../components/CuratedSetCard';

const Home = () => {

    const [articlesToShow, setArticlesToShow] = useState([]);
    const [articlesJSONTitleMatches, setArticlesJSONTitleMatches] = useState([]);
    const [articlesJSONContentMatches, setArticlesJSONContentMatches] = useState([]);
    const [articlesJSONTagMatches, setArticleJSONTagMatches] = useState([]);
    const [glossaryJSON, setGlossaryJSON] = useState([]);
    const [curatedSets, setCuratedSets] = useState({});

    const [searchParams] = useSearchParams();
    const search = searchParams.get('search');
    const bucket = searchParams.get('bucket');

    useEffect(() => {
        if (search) {
            document.getElementById('search-field').value = search;

            /* Get articles where title exactly matches search */
            axios 
                .get(`${process.env.REACT_APP_STRAPI_API_ENDPOINT}/articles?populate=*&filters[title][$eqi]=${encodeURI(search)}`)
                .then(res => setArticlesJSONTitleMatches(res?.data?.data ? res.data.data : []))
                .catch(err => console.error(err));

            /* Get articles whose content contains the search term */
            axios
                .get(`${process.env.REACT_APP_STRAPI_API_ENDPOINT}/articles?populate=*&filters[content][$containsi]=${encodeURI(search)}`)
                .then(res => setArticlesJSONContentMatches(res?.data?.data ? res.data.data : []))
                .catch(err => console.error(err));
              
            /* Get articles whose tags contain the search term */
            axios
                .get(`${process.env.REACT_APP_STRAPI_API_ENDPOINT}/articles?populate=*&filters[tags][name][$containsi]=${encodeURI(search)}`)
                .then(res => setArticleJSONTagMatches(res?.data?.data ? res.data.data : []))
                .catch(err => console.error(err));

            /* Get glossary definitions where the term contains the search term */
            axios
                .get(`${process.env.REACT_APP_STRAPI_API_ENDPOINT}/terms?filters[term][$containsi]=${encodeURI(search)}`)
                .then(res => setGlossaryJSON(res?.data?.data ? res.data.data : []))
                .catch(err => console.error(err));
        } else if (!bucket) {
            /* Get curated sets that are to be displayed on landing page */
            axios
                .get(`${process.env.REACT_APP_STRAPI_API_ENDPOINT}/curated-sets?populate=*&filters[displayOnLandingPage][$eq]=true`)
                .then(res => {
                    let jsonRes = res?.data?.data ? res.data.data : [];
                    // Array<Promise<[name, Array<Article>]>>
                    const curatedPairPromises = jsonRes.map(set => {
                        const name = set.attributes.name;
                        const articles = set.attributes.articles.data;
                        // Array<Promise<Article>>
                        const articlesPromises = articles.map(article => {
                            const articlePromise = axios.get(`${process.env.REACT_APP_STRAPI_API_ENDPOINT}/articles/${article.id}?populate=*`);
                            return articlePromise;
                        });
                        // Promise<Array<Article>>
                        const articlesPromise = Promise.all(articlesPromises);
                        // Promise<[name, Array<Article>]>
                        const pairPromise = articlesPromise.then(articles => [name, articles]);
                        return pairPromise;
                    });
                    // Promise<Array<[name, Array<Article>]>>
                    const curatedPairPromise = Promise.all(curatedPairPromises);
                    // Promise<{ [name]: Array<Article> }>
                    const curatedPromise = curatedPairPromise.then(curatedPairs => {
                        return Object.fromEntries(curatedPairs);
                    })
                    return curatedPromise;
                })
                .then(curated => {
                   setCuratedSets(curated);
                })
                .catch(err => console.error(err));
        }
    }, [search, bucket]);

    useEffect(() => {
        if (bucket) {
            axios
                .get(`${process.env.REACT_APP_STRAPI_API_ENDPOINT}/articles?populate=*&filters[bucket][id][$eq]=${encodeURIComponent(bucket)}`)
                .then(res => setArticlesToShow(res?.data?.data ? res.data.data : []))
                .catch(err => console.error(err));

            /* Get glossary definitions in this bucket */
            axios
                .get(`${process.env.REACT_APP_STRAPI_API_ENDPOINT}/terms?filters[bucket][id][$eq]=${encodeURI(bucket)}`)
                .then(res => setGlossaryJSON(res?.data?.data ? res.data.data : []))
                .catch(err => console.error(err));
            
        }
    }, [bucket])


    useEffect(() => {
        let articles = articlesJSONTitleMatches.concat(articlesJSONContentMatches).concat(articlesJSONTagMatches);
        
        // Prune out any duplicate articles (same id), preserving order
        for (let i = 0; i < articles.length; i++) {
            for (let j = articles.length - 1; j > i; j--) {
                if (articles[i].id === articles[j].id) {
                    articles.splice(j, 1);
                }
            }
        }

        setArticlesToShow(articles);
    }, [articlesJSONTitleMatches, articlesJSONContentMatches, articlesJSONTagMatches]);

    const onClickSearch = () => {
        const searchTerm = document.getElementById('search-field').value;
        window.location.href = encodeURI(`./?search=${searchTerm}`);
    }
    
    const enterPress = e => {
        if (e.key === 'Enter') {
            document.getElementById('search').click();
        }
    }

    const idToBucketName = id => {
        switch (id) {
            case '2': return 'Intellectual Property';
            case '3': return 'Licensing on ONLOCK';
            case '4': return 'Terms & Templates';
            case '5': return 'Resources';
            default: return '';
        }
    }

    return (
        <>
            <Header/>
            <div className='page-content'>
                <h1 className='center'>KNOWLEDGE</h1>
                <p className='center' style={{marginTop: 34}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                <div className='search-div'>
                    <span className='search-magnifying-glass'>⚲</span>
                    <input 
                        id='search-field' 
                        type='text' 
                        placeholder='What are you looking for?' 
                        onKeyDown={enterPress}
                        >
                    </input>
                    <OctagonButton id='search' width={124} height={36} btnStyle='black' onClick={onClickSearch} text='SEARCH'/>
                </div>
                {/* LARGE BUCKET BUTTONS */}
                {!bucket &&
                    <div className='bucket-row'>
                        <OctagonButton width={229} height={140} radius={12} btnStyle='white' onClick={() => window.location.href = '/?bucket=2'} children={
                            <>
                                <div className='bucket-box-title'>INTELLECTUAL PROPERTY</div>
                                <div className='bucket-box-subtitle'>View Case Studies on External Stuff, Videos, Articles, and Copyrights</div>
                            </>}
                        />
                        <OctagonButton width={229} height={140} radius={12} btnStyle='white' onClick={() => window.location.href = '/?bucket=3'} children={
                            <>
                                <div className='bucket-box-title'>LICENSING ON ONLOCK</div>
                                <div className='bucket-box-subtitle'>View Support Articles, How Tos, and Case Studies</div>
                            </>}
                        />
                        <OctagonButton width={229} height={140} radius={12} btnStyle='white' onClick={() => window.location.href = '/?bucket=4'} children={
                            <>
                                <div className='bucket-box-title'>TERMS & TEMPLATES</div>
                                <div className='bucket-box-subtitle'>View Limits of Free Examples</div>
                            </>}
                        />
                        <OctagonButton width={229} height={140} radius={12} btnStyle='white' onClick={() => window.location.href = '/?bucket=5'} children={
                            <>
                                <div className='bucket-box-title'>RESOURCES</div>
                                <div className='bucket-box-subtitle'>View Paralegal Services in Canada, Article on Negotiation</div>
                            </>}
                        />
                    </div>
                }
                {/* SMALL BUCKET BUTTONS */}
                {bucket &&
                    <div className='bucket-row-small'>
                        <OctagonButton text='Intellectual Property' fontSize='12pt' btnStyle={bucket === 2 ? 'black' : 'white'} onClick={() => window.location.href = '/?bucket=2'}/>
                        <OctagonButton text='Licensing on ONLOCK' fontSize='12pt' btnStyle={bucket === 3 ? 'black' : 'white'} onClick={() => window.location.href = '/?bucket=3'}/>
                        <OctagonButton text='Terms & Templates' fontSize='12pt' btnStyle={bucket === 4 ? 'black' : 'white'} onClick={() => window.location.href = '/?bucket=4'}/>
                        <OctagonButton text='Resources' fontSize='12pt' btnStyle={bucket === 5 ? 'black' : 'white'} onClick={() => window.location.href = '/?bucket=5'}/>
                    </div>
                }
                {Object.keys(curatedSets).map((set, i) => <div key={i}>
                    <h2 style={{fontSize: 24, marginBottom: 17}}>{set.toUpperCase()}</h2>
                    <div className='curated-article-row'>
                        {curatedSets[set].map((article, j) => <CuratedSetCard key={j} article={article.data.data}/>)}
                    </div>
                </div>)}
                {articlesToShow.length > 0 && !bucket && <h2 style={{marginBottom: 15}}>ARTICLES WITH THIS TERM</h2>}
                {articlesToShow.length > 0 && bucket && <h2 style={{marginBottom: 15, fontSize: 24}}>{`ARTICLES IN '${idToBucketName(bucket)}'`.toUpperCase()}</h2>}
                <div className='article-row'>
                    {articlesToShow.map(article => <ArticleCard article={article}/>)}
                </div>
                {glossaryJSON.length > 0 && <h2 style={{marginBottom: 15}}>RELATED GLOSSARY</h2>}
                <div className='glossary-row'>
                    {glossaryJSON.map(entry => <GlossaryCard term={entry.attributes.term} definition={entry.attributes.definition}/>)}
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Home;