import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import Header from './Header';
import Footer from './Footer';
import Transcription from '../components/Transcription';
import Breadcrumb from '../components/Breadcrumb';
import ArticleSidebarRight from '../components/ArticleSidebarRight';
import ArticleCard from '../components/ArticleCard';
import OctagonButton from '../components/OctagonButton';
import axios from 'axios';
import '../styles/Article.css';

const Article = () => {

    const [article, setArticle] = useState(null);
    const [author, setAuthor] = useState(null);

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    
    useEffect(() => {
        axios
            .get(process.env.REACT_APP_STRAPI_API_ENDPOINT + `/articles/${id}?populate=*`)
            .then(res => {
                setArticle(res.data.data);
                const authorId = res.data.data?.attributes?.author?.data?.id;
                axios
                    .get(process.env.REACT_APP_STRAPI_API_ENDPOINT + `/authors/${authorId}?populate=*`)
                    .then(res => {
                        setAuthor(res.data.data);
                    })
                    .catch(err => console.error(err));
            })
            .catch(err => console.error(err));
    }, [id]);

    const markdownToHTML = text => {
        console.log(JSON.stringify(text));
        const lines = text.toString().split('\n');
        const elements = [];
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            elements.push(<ReactMarkdown key={i}>{line}</ReactMarkdown>)
        }
        return elements;
    }

    const bannerURL = article?.attributes?.headerImage?.data?.attributes?.url;
    const headerAttribution = article?.attributes?.headerAttribution;
    const contentBucket = article?.attributes?.bucket?.data?.attributes?.name;
    const authorPicture = author?.attributes?.picture?.data?.attributes?.url;
    const authorName = author?.attributes?.name;
    const publishDate = article?.attributes?.published;
    const videoIntroduction = article?.attributes?.videoIntroduction;
    const videoURL = article?.attributes?.videoURL;
    const videoCaption = article?.attributes?.videoCaption;
    const videoTranscript = article?.attributes?.videoTranscript;
    const tags = article?.attributes?.tags?.data;
    const helpful = article?.attributes?.helpful;
    const feedback = article?.attributes?.feedback;
    const readTime = article?.attributes?.readTime;
    const relatedArticles = article?.attributes?.related_articles;
    const articleCTAText = article?.attributes?.articleCTAText;
    const articleCTAButtonText = article?.attributes?.articleCTAButtonText;
    const articleCTAButtonLink = article?.attributes?.articleCTAButtonLink;

    const bucketNameToId = name => {
        switch (name) {
            case 'Intellectual Property': return 2;
            case 'Licensing on ONLOCK': return 3;
            case 'Terms & Templates': return 4;
            case 'Resources': return 5;
            default: return '';
        }
    }

    const breadcrumbLinks = [
        {
            href: '/',
            text: 'Home'
        },
        {
            href: '/?bucket=' + bucketNameToId(contentBucket),
            text: contentBucket
        },
        {
            href: '#',
            text: article?.attributes?.title
        }
    ]

    if (!article) {
        return (
            <>
                <p>No article</p>
            </>
        )
    }

    return (
        <>
            <Header includeSearch={true}/>
            <Breadcrumb links={breadcrumbLinks} />
            {bannerURL && <img className='article-banner' alt='' src={process.env.REACT_APP_STRAPI_UPLOADS_ENDPOINT + bannerURL}/>}
            <div className='article-container'>
                <div className='left-buffer'></div>
                <article id='article' className='article'>
                    {headerAttribution && <div className='header-attribution'><ReactMarkdown>{headerAttribution}</ReactMarkdown></div>}
                    <div className='article-read-time'>{readTime && <span>{readTime} min read</span>}</div>
                    <h1 className='article-title'>{article.attributes.title}</h1>
                    <div className='article-author-card'>
                        {authorPicture && <img className='article-author-img' alt='' src={process.env.REACT_APP_STRAPI_UPLOADS_ENDPOINT + authorPicture}/>}
                        <div>
                            <div className='article-author-by'>By:</div>
                            <div className='article-author-name'>{authorName}</div>
                            <div className='article-author-published'>{publishDate}</div>
                        </div>
                    </div>
                    <div className='divider' style={{marginBottom: 20}}></div>
                    {videoIntroduction && markdownToHTML(videoIntroduction)}
                    {videoURL && <iframe id='embed-video' src={videoURL} frameborder='0' allow='autoplay; encrypted-media' allowfullscreen title='video' width='675px' height='360px'/>}
                    {videoCaption && <div className='article-video-caption'>{videoCaption}</div>}
                    {videoTranscript && <Transcription text={videoTranscript}/>}
                    <div className='article-content'>{markdownToHTML(article.attributes.content)}</div>
                    {(articleCTAText || (articleCTAButtonText && articleCTAButtonLink)) && <div className='article-cta-section'>
                        <div className='separator' style={{marginTop: 18, marginBottom: 39}}></div>
                        {articleCTAText && <p style={{fontSize: 18, marginBottom: 8}}>{articleCTAText}</p>}
                        {articleCTAButtonText && articleCTAButtonLink && <OctagonButton btnStyle='black' height={30} text={articleCTAButtonText} onClick={() => window.location.href = articleCTAButtonLink}/>}
                    </div>}
                </article>
                <div className='right-buffer'>
                    <ArticleSidebarRight tags={tags} id={id} helpful={helpful} feedback={feedback}/>
                </div>
            </div>
            <div className='article-bottom-section'>
                <div className='separator' style={{marginTop: 36}}></div>
                <div style={{color: 'black', fontSize: 18, fontWeight: 500, marginBottom: 8, marginTop: 31}}>Ready to license your artwork in 5 simple steps?</div>
                <OctagonButton text='GET STARTED ON ONLOCK' btnStyle='black' height={36} width={200}/>
                <div className='article-bottom-links'>
                    <a href='https://onlock.ca'>What is ONLOCK?</a>
                    <span>●</span>
                    <a href='https://onlock.ca'>How to create an account on ONLOCK?</a>
                    <span>●</span>
                    <a href='https://onlock.ca'>How to inquire about a license?</a>
                </div>
                {relatedArticles?.data.length > 0 && <div className='related-articles'>
                    <div className='separator' style={{marginBottom: 25}}></div>
                    <h2 style={{fontSize: 24, marginBottom: 25}}>RECOMMENDED FOR YOU</h2>
                    <div className='related-articles-row'>
                        {relatedArticles.data.map((article, i) => <ArticleCard article={article}/>)}
                    </div>   
                </div>}
            </div>
            <Footer />
        </>
    )

}

export default Article;