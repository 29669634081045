import React from 'react';
import '../styles/TagsCard.css'
import OctagonButton from './OctagonButton';

/**
 * Displays the tags of the current article in the sidebar
 * 
 * @param {string[]} tags - array of tags to display 
 * @returns {JSX} React Component 
 */
const TagsCard = ({ tags }) => {
    if (!tags) tags = [];

    return (
        <div className='tags-card'>
            <p style={{marginBottom: 9}}>Tags:</p>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap:'wrap', gap: 11}}>
                {tags.map((tag, i) => {
                    const url = encodeURI('/?search=' + tag);
                    const onClick = () => window.location.href = url;
                    return <OctagonButton key={i} btnStyle='white' onClick={onClick} text={tag}/>
                })}
            </div>
        </div>
    )
}

export default TagsCard;