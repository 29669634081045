import React from "react";

function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
    >
      <g clipPath="url(#clip0_434_2656)">
        <path
          fill="#000"
          d="M13.503 8.952c-2.555 0-4.616 2.029-4.616 4.544 0 2.516 2.06 4.545 4.616 4.545 2.555 0 4.617-2.03 4.617-4.545s-2.062-4.544-4.617-4.544zm0 7.499c-1.651 0-3.001-1.325-3.001-2.955 0-1.63 1.346-2.954 3.001-2.954 1.655 0 3.002 1.325 3.002 2.954 0 1.63-1.35 2.955-3.002 2.955zm5.882-7.685c0 .59-.482 1.06-1.077 1.06a1.066 1.066 0 01-1.076-1.06c0-.585.482-1.06 1.076-1.06.595 0 1.077.475 1.077 1.06zm3.058 1.076c-.069-1.42-.398-2.678-1.455-3.714-1.052-1.036-2.33-1.36-3.772-1.432-1.487-.083-5.943-.083-7.43 0-1.438.067-2.715.392-3.772 1.428S4.632 8.418 4.559 9.838c-.084 1.463-.084 5.85 0 7.313.069 1.42.398 2.677 1.455 3.713 1.057 1.037 2.33 1.361 3.773 1.432 1.486.083 5.942.083 7.429 0 1.442-.067 2.72-.391 3.772-1.431 1.053-1.037 1.383-2.294 1.455-3.714.084-1.464.084-5.846 0-7.31zm-1.92 8.879a3.015 3.015 0 01-1.712 1.685c-1.186.462-3.998.356-5.308.356-1.31 0-4.126.102-5.307-.356a3.015 3.015 0 01-1.712-1.685c-.47-1.167-.362-3.935-.362-5.225 0-1.29-.104-4.062.362-5.224a3.015 3.015 0 011.712-1.685c1.185-.463 3.997-.356 5.307-.356 1.31 0 4.127-.103 5.308.356a3.015 3.015 0 011.711 1.685c.47 1.166.362 3.935.362 5.224 0 1.29.108 4.062-.362 5.225z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_434_2656">
          <path
            fill="#fff"
            d="M0 0H18V20.25H0z"
            transform="translate(4.5 3.375)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Instagram;