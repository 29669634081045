import React from "react";

function LinkedIn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
    >
      <g clipPath="url(#clip0_434_2652)">
        <path
          fill="#000"
          d="M8.587 21.375V9.99h-3.86v11.385h3.86zm-1.93-12.94c1.346 0 2.184-.875 2.184-1.968C8.816 5.35 8.004 4.5 6.683 4.5c-1.32 0-2.183.85-2.183 1.967 0 1.093.837 1.967 2.133 1.967h.025zm4.066 12.94h3.859v-6.358c0-.34.025-.68.127-.924.279-.68.914-1.384 1.98-1.384 1.396 0 1.955 1.044 1.955 2.575v6.091h3.859v-6.528c0-3.498-1.904-5.125-4.443-5.125-2.082 0-2.996 1.141-3.504 1.919h.026V9.989h-3.86c.052 1.069 0 11.386 0 11.386z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_434_2652">
          <path
            fill="#fff"
            d="M0 0H18V16.875H0z"
            transform="translate(4.5 4.5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LinkedIn;