import React from "react";

function Link() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <g clipPath="url(#clip0_434_2641)">
        <path
          fill="#000"
          d="M19.834 8.167h-4.667V10.5h4.667a3.51 3.51 0 013.5 3.5 3.51 3.51 0 01-3.5 3.5h-4.667v2.333h4.667A5.835 5.835 0 0025.667 14a5.835 5.835 0 00-5.833-5.833zm-7 9.333H8.167a3.51 3.51 0 01-3.5-3.5 3.51 3.51 0 013.5-3.5h4.667V8.167H8.167A5.835 5.835 0 002.334 14a5.836 5.836 0 005.833 5.833h4.667V17.5zm-3.5-4.667h9.333v2.334H9.334v-2.334z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_434_2641">
          <path fill="#fff" d="M0 0H28V28H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Link;