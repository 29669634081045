import React from 'react';
import OctagonButton from './OctagonButton';
import '../styles/ArticleCard.css';

/**
 * A card to display information about an article on the landing page or 
 * bottom section of an article; links users to the article specified by
 * article.id
 * 
 * @param {object} article - JSON object containing all information of an article
 * @returns {JSX} React Component
 */
const ArticleCard = ({ article }) => {
    
    const tags = article?.attributes?.tags?.data ? article.attributes.tags.data : [];
    const bucket = article?.attributes?.bucket?.data ? article.attributes.bucket.data : null;

    const convertDate = date => {
        const comps = date.split('-');
        let year = comps[0];
        let month = Number(comps[1]);
        let day = Number(comps[2]);

        switch (month) {
            case 1: month = 'January'; break;
            case 2: month = 'February'; break;
            case 3: month = 'March'; break;
            case 4: month = 'April'; break;
            case 5: month = 'May'; break;
            case 6: month = 'June'; break;
            case 7: month = 'July'; break;
            case 8: month = 'August'; break;
            case 9: month = 'September'; break;
            case 10: month = 'October'; break;
            case 11: month = 'November'; break;
            case 12: month = 'December'; break;
            default: month = 'error'; break;
        }

        return `${day} ${month} ${year}`;
    }

    return (
        <>
            <div className='article-card'>
                <a className='article-title' href={`/article?id=${article.id}`}><h3>{article.attributes.title}</h3></a>
                <div className='article-tags'>
                    {bucket && <OctagonButton text={bucket.attributes.name}/>}
                    {tags.map(tag => <OctagonButton text={tag.attributes.name}/>)}
                </div>
                <div className='article-excerpt'>
                    {article.attributes.excerpt}
                </div>
                <div className='article-card-spacer'></div>
                <div className='article-date'>{convertDate(article.attributes.published)}</div>
            </div>
        </>
    );
}

export default ArticleCard;