import React, { useState } from 'react';
import { Textarea } from '@chakra-ui/react';
import OctagonButton from './OctagonButton';
import axios from 'axios';

/**
 * Section for the article sidebar that gathers user feedback
 * on helpfulness of article
 * 
 * @param {id} id - id of the current article
 * @param {string|number} helpful - count of how many times article has been indicated as helpful 
 * @param {string[]} feedback - array of strings of feedback recieved from readers
 * @returns {JSX} React Component
 */
const FeedbackCard = ({ id, helpful, feedback }) => {
    const [clickedButton, setClickedButton] = useState('');
    const [feedbackText, setFeedbackText] = useState('');

    /** Increment the count of "helpful" indications and save */
    const onClickYes = () => {
        setClickedButton('yes');
        if (!helpful) helpful = 0;
        helpful++;
        axios
            .put(process.env.REACT_APP_STRAPI_API_ENDPOINT + `/articles/${id}`, {'data': {'helpful': helpful}})
            .then(res => console.log('Feedback submitted (helpful ++)'))
            .catch(err => console.error(err));
    }

    const onClickNo = () => {
        setClickedButton('no');
    }

    /** Add reader's comment to array of all feedback and save */
    const onClickSubmit = () => {
        setClickedButton('yes');
        if (!feedback) feedback = [];
        feedback.push(feedbackText);
        axios
            .put(process.env.REACT_APP_STRAPI_API_ENDPOINT + `/articles/${id}`, {'data': {'feedback': feedback}})
            .then(res => console.log('Feedback submitted (comment)'))
            .catch(err => console.error(err));
    }

    return (
        <div className='feedback-card'>
            {clickedButton 
                ? clickedButton === 'yes' 
                    ?   <p>Thank you for your feedback!</p>
                    : 
                        <div>
                            <p>We're sorry to hear that.</p>
                            <p style={{marginBottom: 7}}>Tell us what went wrong.</p>
                            <Textarea 
                                placeholder='Enter your question'
                                value={feedbackText}
                                onChange={e => setFeedbackText(e.target.value)}
                            />
                            <div style={{height: 13}}></div>
                            <OctagonButton width={124} height={36} btnStyle='black' fontWeight='bold' text='SUBMIT' onClick={onClickSubmit}/>
                        </div>
                :
                    <>Did you find this article helpful?
                    <div style={{marginTop: 7, display: 'flex', gap: 20}}>
                        <OctagonButton btnStyle='white' text='YES' onClick={onClickYes} width={50}/>
                        <OctagonButton btnStyle='white' text='NO' onClick={onClickNo} width={50}/>
                    </div></>
            }
        </div>
    );
};

export default FeedbackCard;