const Button = {
    variants: {
      solid: props => {
        let width = 100
        let height = 40
        let bgColor = props.bgColor || 'black';
        let textColor = props.textColor || 'white';
        if (props.w) {
          width = parseInt(props.w)
        }
        if (props.h) {
          height = parseInt(props.h)
        }

        let polysize = height <= 36 ? 6 : 12;

        return {
          bg: bgColor,
          color: textColor,
          clipPath: `polygon(${polysize}px 0, ${width - polysize}px 0%, 100% ${polysize}px, 100% ${height - polysize}px, ${width - polysize}px 100%, ${polysize}px 100%, 0% ${height - polysize}px, 0% ${polysize}px)`
        }
      }
    }
  }

  export default Button;
  