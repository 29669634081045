const Select = {
    variants: {
      outline: {
        field: {
          borderRadius: 0,
          border: "2px solid #828282!important",
          color: "rgba(0, 0, 0, 0.54)"
        }
      }
    }
  }

export default Select;
  