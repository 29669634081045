import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import './index.css';
import reportWebVitals from './reportWebVitals';

import Home from './pages/home';
import Article from './Templates/Article';

import Button from './themes/onlock/Button';
import Input from './themes/onlock/Input';
import Select from './themes/onlock/Select';
import Tabs from './themes/onlock/Tabs';
import TextArea from './themes/onlock/TextArea';

const theme = extendTheme({
  fonts: {
    heading: `'Forma DJR Deck', sans-serif`,
    body: `'Forma DJR Deck', sans-serif`,
  },
  components: {
    Button,
    Input,
    Select,
    Tabs,
    TextArea
  }
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/article',
    element: <Article />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router}/>
      </ChakraProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
